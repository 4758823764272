import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
import util from '@/libs/util';
export default {
  props: {
    limit: Number,
    disabled: Boolean,
    filesname: {
      type: String,
      default: function _default() {
        return 'items';
      }
    },
    fileType: {
      type: String,
      default: function _default() {
        return '.csv';
      }
    },
    title: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    imageList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    Url: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    paramsData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      action: process.env.VUE_APP_API + this.Url,
      showUpload: false,
      //控制limit最大值之后 关闭上传按钮
      headers: {
        'Authorization': '',
        'Request-Url': ''
      }
    };
  },
  created: function created() {
    this.headers['Authorization'] = 'Bearer ' + (util.cookies.get('token') || '');
    this.headers['Request-Url'] = this.$route.path;
  },
  methods: {
    //文件列表移除文件时的函数
    handleRemove: function handleRemove(file, fileList) {},
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.imageUrl = file.url;
      this.dialogVisible = true;
    },
    upErr: function upErr(e) {
      console.log(e);
    },
    beforeUpload: function beforeUpload(file) {
      if (!this.paramsData.appid) {
        this.$message.error('请选择应用');
        return false;
      }

      var typeList = this.fileType.split(',');
      var isTrue = false;
      typeList.map(function (i) {
        if (file.name.indexOf(i) > -1) {
          isTrue = true;
        }
      });
      var isUp = true;
      var size = file.size / 1024 / 1024 / 2;

      if (!isTrue) {
        isUp = false;
        this.$notify.warning({
          title: "警告",
          message: "请上传格式为" + this.fileType + '的文件'
        });
      } else if (size > 100) {
        isUp = false;
        this.$notify.warning({
          title: "警告",
          message: "文件大小必须小于100M"
        });
      }

      return isUp;
    },
    upSuccess: function upSuccess(response) {
      if (response.code == 200 || response.status == 200) {
        this.$message.success('上传成功');
        this.$emit('change', response);
      } else {
        this.$message.error(response.msg || response.message);
      }
    },
    //文件超出个数限制时的函数
    handleExceed: function handleExceed(files, fileList) {
      this.$message.info("\u6700\u591A\u53EA\u5141\u8BB8\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247"));
    },
    // 清空上传的文件
    emptyFeil: function emptyFeil() {
      var _this = this;

      this.$refs.upload.clearFiles();
      setTimeout(function () {
        _this.imgUrl = [];
        _this.fileList = [];
        _this.showUpload = false;
      }, 1000);
    }
  }
};